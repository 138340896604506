<template>
  <div class="page">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <b-card title="Client Information">
          <table class="clientTable">
            <tr>
              <th>First Name</th>
              <td>{{ client.firstName }}</td>
              <th>Last Name</th>
              <td>{{ client.lastName }}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{{ client.email }}</td>
              <th>Phone</th>
              <td>{{ client.phone }}</td>
            </tr>
            <tr>
              <th>Method</th>
              <td>{{ client.type }}</td>
              <th>Status</th>
              <td>{{ client.status }}</td>
            </tr>
            <tr>
              <th>Updated</th>
              <td>{{ client.updated }}</td>
            </tr>
          </table>
          <b-button
            variant="outline-primary"
            @click="$router.push({ name: 'lead-edit', params: { id: $route.params.id } })"
          >
            Edit
          </b-button>
        </b-card>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <b-card title="Average Information">
          <table class="averageTable">
            <tr>
              <th>Number of Properties</th>
              <td>{{ Object.keys(listings).length }}</td>
            </tr>
            <tr>
              <th>Average Price</th>
              <td>${{ commafy(average.price) }}</td>
            </tr>
            <tr>
              <th>Property Type</th>
              <td>{{ average.propertyType }}</td>
            </tr>
            <tr>
              <th>Beds</th>
              <td>{{ average.beds }}</td>
            </tr>
            <tr>
              <th>Baths</th>
              <td>{{ average.baths }}</td>
            </tr>
          </table>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <b-card :title="'Interested Cities'">
          <b-dropdown
            id="dropdown-1"
            :text="selectedChartOption"
            variant="outline-primary"
          >
            <b-dropdown-item @click="onChartOptClick('In Percentage')">
              <span>In Percentage</span>
            </b-dropdown-item>

            <b-dropdown-item @click="onChartOptClick('In Count')">
              <span>In Count</span>
            </b-dropdown-item>
          </b-dropdown>
          <Popular
            :data="mostPopular.cities"
            :option="selectedChartOption"
            title="Cities"
          />
        </b-card>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <b-card :title="'Interested Sub Areas'">
          <b-dropdown
            id="dropdown-1"
            :text="selectedChartOption"
            variant="outline-primary"
          >
            <b-dropdown-item @click="onChartOptClick('In Percentage')">
              <span>In Percentage</span>
            </b-dropdown-item>

            <b-dropdown-item @click="onChartOptClick('In Count')">
              <span>In Count</span>
            </b-dropdown-item>
          </b-dropdown>
          <Popular
            :data="mostPopular.subAreas"
            :option="selectedChartOption"
            title="Subareas"
          />
        </b-card>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <b-card :title="'Interested Property Types'">
          <b-dropdown
            id="dropdown-1"
            :text="selectedChartOption"
            variant="outline-primary"
          >
            <b-dropdown-item @click="onChartOptClick('In Percentage')">
              <span>In Percentage</span>
            </b-dropdown-item>

            <b-dropdown-item @click="onChartOptClick('In Count')">
              <span>In Count</span>
            </b-dropdown-item>
          </b-dropdown>
          <Popular
            :data="mostPopular.types"
            :option="selectedChartOption"
            title="Types"
          />
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-card title="Listing Viewed History">
          <b-table
            responsive="sm"
            :items="listings"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #cell(price)="data">
              ${{ commafy(data.value) }}
            </template>

          </b-table>

          <b-pagination
            v-model="currentPage"
            class="d-flex justify-content-center"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          />
        </b-card>

      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BTable, BPagination, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Popular from './components/Popular.vue'

export default {
  components: {
    BCard, Popular, BTable, BPagination, BButton, BDropdown, BDropdownItem,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      listings: [],
      average: {
        price: '0',
        propertyType: '',
        beds: '',
        baths: '',
        city: '',
      },
      client: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        source: '',
        type: '',
        created: '',
        status: '',
        hasBeen: '',
      },
      mostPopular: {
        cities: [],
        subAreas: [],
        types: [],
      },
      cityTotal: 0,
      subareaTotal: 0,
      typeTotal: 0,
      selectedChartOption: 'In Percentage',
    }
  },
  computed: {
    rows() {
      return this.listings.length
    },
  },
  created() {
    if (this.$store.state.clientInfo.detail.website) {
      this.$store.dispatch('leads/getLeads', { id: this.$route.params.id, website: this.$store.state.clientInfo.detail.website }).then(response => {
        this.listings = response.data.filter(data => {
          return data.mls_id
        }).map(data => {
          return {
            mlsId: data.mls_id,
            address: data.address,
            subArea: data.subarea,
            city: data.city,
            price: data.asking_price,
            propertyType: data.type,
            beds: data.bed,
            baths: data.bath,
          }
        })
        if (Object.keys(this.listings).length > 0) {
          this.getStats(this.listings)
          this.getAverage(this.listings)
        }
      }).catch(err => {
        console.error(err)
      })
    }
    this.$store.dispatch('leads/getLeadUser', { id: this.$route.params.id }).then(response => {
      this.client = {
        firstName: response.data.data.first_name,
        lastName: response.data.data.last_name,
        email: response.data.data.email,
        phone: response.data.data.phone,
        type: response.data.data.type,
        status: response.data.data.status,
        updated: this.formatDate(response.data.data.updated_at),
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString()
    },
    getAverage(data) {
      let priceCount = 0
      let priceTotal = 0

      const properties = {}

      let maxNumofBeds = 0
      let maxNumofBaths = 0

      data.forEach(single => {
        if (single.price) {
          priceTotal += parseFloat(single.price)
          priceCount++
        }

        if (single.propertyType) {
          if (properties[single.propertyType]) {
            properties[single.propertyType].count += 1
          } else {
            properties[single.propertyType] = {}
            properties[single.propertyType].count = 1
          }
        }

        if (single.beds && maxNumofBeds < parseFloat(single.beds)) {
          maxNumofBeds = parseFloat(single.beds)
        }

        if (single.baths && maxNumofBaths < parseFloat(single.baths)) {
          maxNumofBaths = parseFloat(single.baths)
        }
      })

      this.average.propertyType = Object.keys(properties).reduce((a, b) => (properties[a].count > properties[b].count ? a : b))
      this.average.beds = maxNumofBeds
      this.average.baths = maxNumofBaths
      this.average.price = (priceTotal / priceCount).toFixed(0)
    },
    commafy(num) {
      const str = num.toString().split('.')
      if (str[0].length >= 5) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      }
      if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ')
      }
      return str.join('.')
    },
    getStats(data) {
      const cities = []
      const subareas = []
      const types = []
      data.forEach(single => {
        if (single.city && cities[single.city]) {
          cities[single.city]++
        } else if (single.city) {
          cities[single.city] = 1
        }
        if (single.subArea && subareas[single.subArea]) {
          subareas[single.subArea]++
        } else if (single.subArea) {
          subareas[single.subArea] = 1
        }
        if (single.propertyType && types[single.propertyType]) {
          types[single.propertyType]++
        } else if (single.propertyType) {
          types[single.propertyType] = 1
        }
      })

      Object.keys(cities).forEach(key => {
        this.cityTotal = this.cityTotal + cities[key]
      })
      Object.keys(cities).forEach(key => {
        this.mostPopular.cities.push({ key, value: ((cities[key] / this.cityTotal) * 100).toFixed(2), count: cities[key] })
      })

      Object.keys(subareas).forEach(key => {
        this.subareaTotal = this.subareaTotal + subareas[key]
      })
      Object.keys(subareas).forEach(key => {
        this.mostPopular.subAreas.push({ key, value: ((subareas[key] / this.subareaTotal) * 100).toFixed(2), count: subareas[key] })
      })

      Object.keys(types).forEach(key => {
        this.typeTotal = this.typeTotal + types[key]
      })
      Object.keys(types).forEach(key => {
        this.mostPopular.types.push({ key, value: ((types[key] / this.typeTotal) * 100).toFixed(2), count: types[key] })
      })
    },
    onChartOptClick(opt) {
      this.selectedChartOption = opt
    },
  },
}
</script>

<style lang="scss">
.popular{
  align-items:center;
  padding:5px 0;
  .title{
    width:120px;
  }
  .progressBar{
    width:calc(100% - 190px);
  }
  .percent{
    width:80px;
    text-align:right;
  }
}
.clientTable, .averageTable{
  th{
    padding-right:10px;
    padding-bottom:10px;
  }
  td{
    padding-bottom:10px;
    padding-right:35px;
  }
}
</style>
